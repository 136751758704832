import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Message } from './message';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ContactUsService {

  constructor(private http: HttpClient) { 

  }
  
  postMessage (message: Message):  Observable<String> {
    
    let apiUrl = "https://us-central1-hays-properties.cloudfunctions.net/checkRecaptcha-test";
                  
    return this.http.post<String>(apiUrl, message);
  }
  
}
