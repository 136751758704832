import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ListingsComponent }  from './listings/listings.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

const routes: Routes = [
  { path: '', redirectTo: '/listings', pathMatch: 'full' },
  { path: 'listings', component: ListingsComponent },
  { path: 'contactus', component: ContactUsComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes,{useHash:true}) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule {}