import { Listing } from './listing';

export const LISTINGS: Listing[] = [
			{
				"listingId": "listing_001",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Maui Ocean Club, Maui, Hawaii",
				"dateCheckIn": "Check-in: Sunday, January 21, 2018 4:00 PM",
				"dateCheckOut": "Check-out: Sunday, January 28, 2018 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,500",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,200 sqft",
				"kitchenDescription": "Kitchenette",
				"viewDescription": "Ocean View",
				"imageUrl": "./images/pic-mu-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_002",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Check-in: Saturday, March 31, 2018 4:30 PM",
				"dateCheckOut": "Check-out: Saturday, April 7, 2018 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,250",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_003",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, May 11, 2018 4:00 PM",
				"dateCheckOut": "Saturday, May 18, 2018 10:00 AM",
				"duration": "7 Nights",
				"price": "$1,750",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-04.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_004",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, July 7, 2018 4:00 PM",
				"dateCheckOut": "Saturday, July 14, 2018 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,250",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_005",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, July 7, 2018 4:00 PM",
				"dateCheckOut": "Saturday, July 14, 2018 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,250",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_006",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, July 14, 2018 4:30 PM",
				"dateCheckOut": "Saturday, July 21, 2018 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,595",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_007",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, July 14, 2018 4:30 PM",
				"dateCheckOut": "Saturday, July 21, 2018 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,595",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-04.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_008",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Disney's Grand Californian, Anaheim, California",
				"dateCheckIn": "Sunday, August 19, 2018 4:00 PM",
				"dateCheckOut": "Tuesday, August 21, 2018 10:00 AM",
				"duration": "2 Nights",
				"price": "$950",
				"bedroomDescription": "Studio",
				"bathroomDescription": "1 Ba",
				"sqFootageDescription": "500 sqft",
				"kitchenDescription": "Kitchenette",
				"viewDescription": null,
				"imageUrl": "./images/pic-dg-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_009",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, December 29, 2018 4:30 PM",
				"dateCheckOut": "Saturday, January 5, 2019 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,950",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "3 Ba",
				"sqFootageDescription": "1,670 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_010",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, December 29, 2018 4:30 PM",
				"dateCheckOut": "Saturday, January 5, 2019 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,950",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "3 Ba",
				"sqFootageDescription": "1,670 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-03.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_011",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, March 9, 2019 4:00 PM",
				"dateCheckOut": "Saturday, March 16, 2019 10:00 AM",
				"duration": "7 Nights",
				"price": "$1,895",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-04.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_012",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Sunday, March 17, 2019 4:30 PM",
				"dateCheckOut": "Sunday, March 24, 2019 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,775",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "3 Ba",
				"sqFootageDescription": "1,670 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-04.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_013",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Hyatt Residence Club Sedona, Pinon Pointe, Sedona, Arizona",
				"dateCheckIn": "Saturday, March 30, 2019 4:00 PM",
				"dateCheckOut": "Saturday, April 6, 2019 10:00 AM",
				"duration": "7 Nights",
				"price": "$1,195",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "1 Ba",
				"sqFootageDescription": "975 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-pp-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_014",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, July 6, 2019 4:30 PM",
				"dateCheckOut": "Saturday, July 13, 2019 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,295",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_015",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca",
				"dateCheckIn": "Sunday, July 7, 2019 4:00 PM",
				"dateCheckOut": "Sunday, July 14, 2019 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,250",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_016",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca",
				"dateCheckIn": "Sunday, July 7, 2019 4:00 PM",
				"dateCheckOut": "Sunday, July 14, 2019 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,250",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_017",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Ko Olina Beach Club, Oahu, Hawaii",
				"dateCheckIn": "Sunday, July 21, 2019 4:00 PM",
				"dateCheckOut": "Sunday, July 28, 2019 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,245",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-ko-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_018",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca",
				"dateCheckIn": "Saturday, July 27, 2019 4:00 PM",
				"dateCheckOut": "Saturday, August 3, 2019 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,395",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-03.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_019",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, August 18, 2019 4:30 PM",
				"dateCheckOut": "Saturday, August 25, 2019 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,350",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-04.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_020",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, December 28, 2019 4:30 PM",
				"dateCheckOut": "Saturday, January 4, 2020 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,955",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "3 Ba",
				"sqFootageDescription": "1,670 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_021",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, December 28, 2019 4:30 PM",
				"dateCheckOut": "Saturday, January 4, 2020 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,650",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-03.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_022",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Sunday, March 8, 2020 4:00 PM",
				"dateCheckOut": "Sunday, March 15, 2020 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,045",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-04.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_023",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Hyatt Residence Club Sedona, Pinon Pointe, Sedona, Arizona",
				"dateCheckIn": "Saturday, March 28, 2020 4:00 PM",
				"dateCheckOut": "Saturday, April 4, 2020 10:00 AM",
				"duration": "7 Nights",
				"price": "$1,795",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "1 Ba",
				"sqFootageDescription": "975 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-pp-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_024",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, June 20, 2020 4:30 PM",
				"dateCheckOut": "Saturday, June 27, 2020 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,595",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-04.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_025",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca",
				"dateCheckIn": "Saturday, July 11, 2020 4:00 PM",
				"dateCheckOut": "Saturday, July 18, 2020 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,195",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_026",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca",
				"dateCheckIn": "Saturday, July 11, 2020 4:00 PM",
				"dateCheckOut": "Saturday, July 18, 2020 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,195",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-02.jpg",
				"enabled": "false"
			},
            {
				"listingId": "listing_027",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Maui Ocean Club, Maui, Hawaii",
				"dateCheckIn": "Check-in: Saturday, August 8, 2020 4:00 PM",
				"dateCheckOut": "Check-out: Saturday, August 15, 2020 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,950",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,200 sqft",
				"kitchenDescription": "Kitchenette",
				"viewDescription": "Ocean View",
				"imageUrl": "./images/pic-mu-01.jpg",
				"enabled": "false"
			},
            {
				"listingId": "listing_028",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Hyatt Residence Club, Highlands Inn, Carmel, California",
				"dateCheckIn": "Tuesday, August 11, 2020 4:00 PM",
				"dateCheckOut": "Saturday, April 15, 2020 10:00 AM",
				"duration": "4 Nights",
				"price": "$1,095",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "1 Ba",
				"sqFootageDescription": "975 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-hi-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_029",
				"listingType": "Vacation Rental",
				"listingStatus": "Available",
				"listingStatusClass": "available",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, September 12, 2020 4:30 PM",
				"dateCheckOut": "Saturday, September 19, 2020 10:00 AM",
				"duration": "7 Nights",
				"price": "$1,895",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-03.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_030",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, December 26, 2020 4:30 PM",
				"dateCheckOut": "Saturday, January 2, 2021 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,075",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_031",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, December 26, 2020 4:30 PM",
				"dateCheckOut": "Saturday, January 2, 2021 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,995",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "3 Ba",
				"sqFootageDescription": "1,670 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-01.jpg",
				"enabled": "false"
			},			
			{
				"listingId": "listing_032",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, March 13, 2021 4:00 PM",
				"dateCheckOut": "Saturday, March 20, 2021 10:00 AM",
				"duration": "7 Nights",
				"price": "$1,595",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-04.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_033",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Hyatt Residence Club Sedona, Pinon Pointe, Sedona, Arizona",
				"dateCheckIn": "Saturday, March 27, 2021 4:00 PM",
				"dateCheckOut": "Saturday, April 3, 2021 10:00 AM",
				"duration": "7 Nights",
				"price": "$1,795",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "1 Ba",
				"sqFootageDescription": "975 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-pp-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_034",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, July 10, 2021 4:00 PM",
				"dateCheckOut": "Saturday, July 17, 2021 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,200",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_035",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, July 10, 2021 4:00 PM",
				"dateCheckOut": "Saturday, July 17, 2021 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,200",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_036",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, July 10, 2021 4:00 PM",
				"dateCheckOut": "Saturday, July 17, 2021 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,200",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-03.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_037",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Ko Olina Beach Club, Oahu, Hawaii",
				"dateCheckIn": "Saturday, July 10, 2021 4:00 PM",
				"dateCheckOut": "Saturday, July 17, 2021 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,595",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-ko-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_038",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Sunday, August 1, 2021 4:30 PM",
				"dateCheckOut": "Sunday, August 8, 2021 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,295",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_039",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Sunday, August 8, 2021 4:30 PM",
				"dateCheckOut": "Sunday, August 15, 2021 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,295",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-03.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_040",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Friday, December 17, 2021 4:30 PM",
				"dateCheckOut": "Friday, December 24, 2021 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,295",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_041",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, December 18, 2021 4:30 PM",
				"dateCheckOut": "Saturday, December 25, 2021 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,145",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "3 Ba",
				"sqFootageDescription": "1,670 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_042",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, December 25, 2021 4:30 PM",
				"dateCheckOut": "Saturday, January 1, 2022 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,095",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "3 Ba",
				"sqFootageDescription": "1,670 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-03.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_043",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, March 12, 2022 4:00 PM",
				"dateCheckOut": "Saturday, March 19, 2022 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,395",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-04.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_044",
				"listingType": "Vacation Rental",
				"listingStatus": "Available",
				"listingStatusClass": "available",
				"propertyName": "Hyatt Residence Club Sedona, Pinon Pointe, Sedona, Arizona",
				"dateCheckIn": "Saturday, April 2, 2022 4:00 PM",
				"dateCheckOut": "Saturday, April 9, 2022 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,595",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "1 Ba",
				"sqFootageDescription": "975 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-pp-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_050",
				"listingType": "Vacation Rental",
				"listingStatus": "Available",
				"listingStatusClass": "available",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Sunday, June 26, 2022 4:30 PM",
				"dateCheckOut": "Sunday, July 3, 2022 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,995",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-03.jpg",
				"enabled": "false"
			},
            {
				"listingId": "listing_045",
				"listingType": "Vacation Rental",
				"listingStatus": "Available",
				"listingStatusClass": "available",
				"propertyName": "Marriott's Maui Ocean Club, Maui, Hawaii",
				"dateCheckIn": "Check-in: Friday, July 1, 2022 4:00 PM",
				"dateCheckOut": "Check-out: Friday, July 8, 2022 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,250",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,200 sqft",
				"kitchenDescription": "Kitchenette",
				"viewDescription": "Ocean View",
				"imageUrl": "./images/pic-mu-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_046",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Friday, July 8, 2021 4:00 PM",
				"dateCheckOut": "Friday, July 15, 2021 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,795",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_047",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, July 9, 2022 4:00 PM",
				"dateCheckOut": "Saturday, July 16, 2022 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,250",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_048",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Sunday, July 10, 2022 4:00 PM",
				"dateCheckOut": "Sunday, July 17, 2022 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,250",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-03.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_049",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, August 6, 2022 4:30 PM",
				"dateCheckOut": "Saturday, August 13, 2022 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,795",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_051",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Sunday, December 25, 2022 4:30 PM",
				"dateCheckOut": "Sunday, January 1, 2023 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,995",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_052",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Sunday, December 25, 2022 4:30 PM",
				"dateCheckOut": "Sunday, January 1, 2023 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,295",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "3 Ba",
				"sqFootageDescription": "1,670 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_053",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Sunday, December 25, 2022 4:30 PM",
				"dateCheckOut": "Sunday, January 1, 2023 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,450",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "3 Ba",
				"sqFootageDescription": "1,670 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-03.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_054",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, March 11, 2023 4:00 PM",
				"dateCheckOut": "Saturday, March 18, 2023 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,995",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-04.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_055",
				"listingType": "Vacation Rental",
				"listingStatus": "Available",
				"listingStatusClass": "available",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, March 18, 2023 4:30 PM",
				"dateCheckOut": "Saturday, March 25, 2023 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,495",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_056",
				"listingType": "Vacation Rental",
				"listingStatus": "Available",
				"listingStatusClass": "available",
				"propertyName": "Hyatt Residence Club Sedona, Pinon Pointe, Sedona, Arizona",
				"dateCheckIn": "Saturday, April 1, 2023 4:00 PM",
				"dateCheckOut": "Saturday, April 8, 2023 10:00 AM",
				"duration": "7 Nights",
				"price": "$1,895",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "1 Ba",
				"sqFootageDescription": "975 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-pp-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_057",
				"listingType": "Vacation Rental",
				"listingStatus": "Available",
				"listingStatusClass": "available",
				"propertyName": "Marriott's Ko Olina Beach Club, Oahu, Hawaii",
				"dateCheckIn": "Saturday, July 1, 2023 4:00 PM",
				"dateCheckOut": "Saturday, July 8, 2023 10:00 AM",
				"duration": "7 Nights",
				"price": "$4,995",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-ko-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_058",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, July 8, 2023 4:00 PM",
				"dateCheckOut": "Saturday, July 15, 2023 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,250",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_059",
				"listingType": "Vacation Rental",
				"listingStatus": "Available",
				"listingStatusClass": "available",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Sunday, July 9, 2023 4:30 PM",
				"dateCheckOut": "Sunday, July 16, 2022 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,195",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_060",
				"listingType": "Vacation Rental",
				"listingStatus": "Available",
				"listingStatusClass": "available",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Sunday, July 16, 2023 4:30 PM",
				"dateCheckOut": "Sunday, July 23, 2022 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,195",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-03.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_061",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Friday, July 21, 2023 4:00 PM",
				"dateCheckOut": "Friday, July 28, 2023 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,295",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_062",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Friday, July 21, 2023 4:00 PM",
				"dateCheckOut": "Friday, July 28, 2023 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,295",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-03.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_063",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Friday, July 21, 2023 4:00 PM",
				"dateCheckOut": "Friday, July 28, 2023 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,295",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_064",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Friday, December 29, 2023 4:30 PM",
				"dateCheckOut": "Friday, January 5, 2024 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,495",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "3 Ba",
				"sqFootageDescription": "1,670 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_065",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Friday, December 29, 2023 4:30 PM",
				"dateCheckOut": "Friday, January 5, 2024 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,895",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "3 Ba",
				"sqFootageDescription": "1,670 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-03.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_066",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, March 16, 2024 4:00 PM",
				"dateCheckOut": "Saturday, March 23, 2024 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,895",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-04.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_067",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Hyatt Residence Club Sedona, Pinon Pointe, Sedona, Arizona",
				"dateCheckIn": "Saturday, March 30, 2024 4:00 PM",
				"dateCheckOut": "Saturday, April 6, 2024 10:00 AM",
				"duration": "7 Nights",
				"price": "$1,995",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "1 Ba",
				"sqFootageDescription": "975 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-pp-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_068",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, June 22, 2024 4:00 PM",
				"dateCheckOut": "Saturday, June 29, 2024 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,595",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_069",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, June 22, 2024 4:00 PM",
				"dateCheckOut": "Saturday, June 29, 2024 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,595",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-03.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_070",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, June 22, 2024 4:00 PM",
				"dateCheckOut": "Saturday, June 29, 2024 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,595",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_071",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Friday, July 5, 2024 4:30 PM",
				"dateCheckOut": "Friday, July 12, 2024 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,295",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_072",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Friday, July 12, 2024 4:30 PM",
				"dateCheckOut": "Friday, July 19, 2024 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,295",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-03.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_073",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Maui Ocean Club, Maui, Hawaii",
				"dateCheckIn": "Check-in: Saturday, July 20, 2024 4:00 PM",
				"dateCheckOut": "Check-out: Saturday, July 27, 2024 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,595",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,200 sqft",
				"kitchenDescription": "Kitchenette",
				"viewDescription": "Ocean View",
				"imageUrl": "./images/pic-mu-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_074",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Saturday, November 23, 2024 4:30 PM",
				"dateCheckOut": "Saturday, November 30, 2024 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,295",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-01.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_075",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Friday, December 27, 2024 4:30 PM",
				"dateCheckOut": "Friday, January 3, 2025 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,895",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "3 Ba",
				"sqFootageDescription": "1,670 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-02.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_076",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Friday, December 27, 2024 4:30 PM",
				"dateCheckOut": "Friday, January 3, 2025 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,895",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "3 Ba",
				"sqFootageDescription": "1,670 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-03.jpg",
				"enabled": "false"
			},
			{
				"listingId": "listing_077",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, March 15, 2025 4:00 PM",
				"dateCheckOut": "Saturday, March 22, 2025 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,795",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-03.jpg",
				"enabled": "true"
			},
			{
				"listingId": "listing_078",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Hyatt Residence Club Sedona, Pinon Pointe, Sedona, Arizona",
				"dateCheckIn": "Saturday, March 29, 2025 4:00 PM",
				"dateCheckOut": "Saturday, April 5, 2025 10:00 AM",
				"duration": "7 Nights",
				"price": "$1,995",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "1 Ba",
				"sqFootageDescription": "975 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-pp-01.jpg",
				"enabled": "true"
			},
			{
				"listingId": "listing_079",
				"listingType": "Vacation Rental",
				"listingStatus": "Available",
				"listingStatusClass": "available",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, July 5, 2025 4:00 PM",
				"dateCheckOut": "Saturday, July 12, 2025 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,295",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-02.jpg",
				"enabled": "true"
			},
			{
				"listingId": "listing_080",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Ko Olina Beach Club, Oahu, Hawaii",
				"dateCheckIn": "Saturday, July 12, 2025 4:00 PM",
				"dateCheckOut": "Saturday, July 19, 2025 10:00 AM",
				"duration": "7 Nights",
				"price": "$4,995",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-ko-02.jpg",
				"enabled": "true"
			},
			{
				"listingId": "listing_081",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, July 12, 2025 4:00 PM",
				"dateCheckOut": "Saturday, July 19, 2025 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,995",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-01.jpg",
				"enabled": "true"
			},
			{
				"listingId": "listing_082",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Saturday, July 12, 2025 4:00 PM",
				"dateCheckOut": "Saturday, July 19, 2025 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,995",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-02.jpg",
				"enabled": "true"
			},
			{
				"listingId": "listing_083",
				"listingType": "Vacation Rental",
				"listingStatus": "Rented",
				"listingStatusClass": "rented",
				"propertyName": "Marriott's Newport Coast Villas, Newport Coast, Ca.",
				"dateCheckIn": "Sunday, July 13, 2025 4:00 PM",
				"dateCheckOut": "Sunday, July 20, 2025 10:00 AM",
				"duration": "7 Nights",
				"price": "$2,995",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,248 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-nc-03.jpg",
				"enabled": "true"
			},
			{
				"listingId": "listing_084",
				"listingType": "Vacation Rental",
				"listingStatus": "Available",
				"listingStatusClass": "available",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Sunday, August 10, 2025 4:30 PM",
				"dateCheckOut": "Sunday, August 17, 2025 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,295",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-01.jpg",
				"enabled": "true"
			},
			{
				"listingId": "listing_085",
				"listingType": "Vacation Rental",
				"listingStatus": "Available",
				"listingStatusClass": "available",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Sunday, August 17, 2025 4:30 PM",
				"dateCheckOut": "Sunday, August 24, 2025 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,295",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-02.jpg",
				"enabled": "true"
			},
			{
				"listingId": "listing_086",
				"listingType": "Vacation Rental",
				"listingStatus": "Available",
				"listingStatusClass": "available",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Sunday, December 28, 2025 4:30 PM",
				"dateCheckOut": "Sunday, January 4, 2026 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,495",
				"bedroomDescription": "1 Bd",
				"bathroomDescription": "2 Ba",
				"sqFootageDescription": "1,330 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-01.jpg",
				"enabled": "true"
			},
			{
				"listingId": "listing_087",
				"listingType": "Vacation Rental",
				"listingStatus": "Available",
				"listingStatusClass": "available",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Sunday, December 28, 2025 4:30 PM",
				"dateCheckOut": "Sunday, January 4, 2026 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,995",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "3 Ba",
				"sqFootageDescription": "1,670 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-02.jpg",
				"enabled": "true"
			},
			{
				"listingId": "listing_088",
				"listingType": "Vacation Rental",
				"listingStatus": "Available",
				"listingStatusClass": "available",
				"propertyName": "Four Seasons Aviara, Carlsbad, California",
				"dateCheckIn": "Sunday, December 28, 2025 4:30 PM",
				"dateCheckOut": "Sunday, January 4, 2026 10:00 AM",
				"duration": "7 Nights",
				"price": "$3,995",
				"bedroomDescription": "2 Bd",
				"bathroomDescription": "3 Ba",
				"sqFootageDescription": "1,670 sqft",
				"kitchenDescription": "Full Kitchen",
				"viewDescription": null,
				"imageUrl": "./images/pic-av-03.jpg",
				"enabled": "true"
			}
		];