import { Component, OnInit } from '@angular/core';
import { LISTINGS } from '../listings-data';
import { Listing } from '../listing';

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.css']
})
export class ListingsComponent implements OnInit {
  
  rows: { 
    index: number, 
    showlisting1: Boolean, 
    listing1: Listing, 
    showlisting2: Boolean, 
    listing2: Listing, 
    showlisting3: Boolean, 
    listing3: Listing, 
    showlisting4: Boolean, 
    listing4: Listing }[] = [];
  rowCount: number = Math.ceil(LISTINGS.length / 4);

  constructor() { }

  ngOnInit() {
    this.loadRows();
  }
  
  listings = LISTINGS.filter(l => l.enabled === "true");
  
  loadRows(){
    var i = 0;
	var totalListingCount = this.listings.length;
    var totalRowCount = Math.ceil(totalListingCount / 4);
    var hideCnt = ((totalListingCount % 4) === 0) ? 0 : 4 - (totalListingCount % 4);

    //console.log('...totalRowCount = ' + totalRowCount);
	//console.log('...hideCnt = ' + hideCnt);
	
    while (i < totalRowCount){
      var offset = (i===0) ? -1 : (i*3)-1; 
      var lastRow = i === (totalRowCount-1);

      //console.log('...lastRow = ' + lastRow);
      //console.log('...hideCnt = ' + hideCnt);

      var showlisting1Value = (lastRow && hideCnt >= 4) ? false : true;
      var showlisting2Value = (lastRow && hideCnt >= 3) ? false : true;
      var showlisting3Value = (lastRow && hideCnt >= 2) ? false : true;
      var showlisting4Value = (lastRow && hideCnt >= 1) ? false : true;

      this.rows.push({
        index: i, 
        showlisting1: showlisting1Value,
        listing1: showlisting1Value ? this.listings[(i+1) + offset] : null,
        showlisting2: showlisting2Value,
        listing2: showlisting2Value ? this.listings[(i+2) + offset] : null,
        showlisting3: showlisting3Value,
        listing3: showlisting3Value ? this.listings[(i+3) + offset] : null,
        showlisting4: showlisting4Value,
        listing4: showlisting4Value ? this.listings[(i+4) + offset] : null
      });

      i++;
    }
  }
}
