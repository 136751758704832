import { Component, OnInit } from '@angular/core';
import { Message } from '../message';
import { ContactUsService } from '../contact-us.service';

declare var grecaptcha: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  providers: [ ContactUsService ],
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  message: Message = {
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    recaptchaEncodedResponse: ''
  };
  
  messageSentWithSuccess: Boolean = false;
  messageFailed: Boolean = false;

  constructor(private contactUsService: ContactUsService) { }

  ngOnInit() {
    this.addScript();
  }

  send(message: Message): void { 

    const response = grecaptcha.getResponse();
    
    message.recaptchaEncodedResponse = response;

    this.contactUsService.postMessage(message).subscribe(res => { 
        this.messageSentWithSuccess = true;		
      },err => {
        this.messageFailed = true;
      }
    );
  }

  addScript() {
    let script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
}
